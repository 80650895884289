import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { styledComponent } from '../styles/styled'
import { Box } from '../components/Box'

export const Container = styledComponent(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  overflow: 'hidden'
}))

export const Card = styledComponent(Box)(({ theme }) => ({
  marginRight: '8px',

  [theme.breakpoints.up('md')]: {
    marginRight: '24px'
  }
}))

export const SkeletonImageWrapper = styledComponent(Box)(({ theme }) => ({
  width: '136px',
  height: '224px',

  [theme.breakpoints.up('md')]: {
    width: '206px',
    height: '330px'
  }
}))

interface BookCarouselSkeletonProps {
  groupSize?: number
}
export const BookCarouselSkeleton = ({ groupSize = 6 }: BookCarouselSkeletonProps) => (
  <Container>
    {Array(groupSize)
      .fill(1)
      .map((_, i) => (
        <Card key={`BookCarouselSkeleton-${i}`}>
          <SkeletonImageWrapper>
            <Skeleton width="100%" height="100%" />
          </SkeletonImageWrapper>
          <Skeleton width="100%" height="16px" style={{ margin: '8px 0px 3px' }} />
          <Skeleton width="80%" height="12px" style={{ margin: '8px 0px 3px' }} />
          <Skeleton width="65px" height="32px" style={{ marginTop: '16px', borderRadius: '50px' }} />
        </Card>
      ))}
  </Container>
)
