'use client'

import {
  BookCard,
  BookCarouselSkeleton,
  Box,
  Carousel,
  Typography,
  useMediaQuery,
  useTheme
} from '@hermes/web-components'
import { useState } from 'react'

import { BookDto } from '@hermes/domains'
import { useNavigation } from '@hooks/useNavigation'
import useTranslateMessage from '@hooks/useTranslateMessage'
import BookImage from './BookImage'

interface BookCarouselProps {
  title: string
  books: BookDto[] | undefined
  groupSize?: number
}

export const BookCarousel = ({ title, books, groupSize = 6 }: BookCarouselProps) => {
  const { t } = useTranslateMessage()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const [navigationIndex, setNavigationIndex] = useState(1)
  const { navigate } = useNavigation()

  const leftNavigation = () => {
    if (navigationIndex === 1) {
      return
    }

    setNavigationIndex(navigationIndex - 1)
  }

  const rightNavigation = () => {
    const newIndex = navigationIndex + 1

    setNavigationIndex(newIndex)
  }

  const bookClicked = (slug: string) => {
    navigate.productPage(slug)
  }

  return (
    <Box pb="40px" pt="40px" suppressHydrationWarning>
      <Typography variant="h2" mb="32px" fontSize="24px" lineHeight="32px">
        {title}
      </Typography>
      {!books ? (
        <BookCarouselSkeleton groupSize={groupSize} />
      ) : (
        <Carousel
          leftNavigation={leftNavigation}
          rightNavigation={rightNavigation}
          navigation={isDesktop}
          slidesPerGroup={isDesktop ? 6 : 2}
        >
          {books.map((book) => {
            const cheapestListing = book.availability.totalPrice
            const hasOffer = !!cheapestListing
            return (
              <BookCard
                key={book.id}
                title={book.title}
                cardAction={() => bookClicked(book.slug)}
                chipAction={() => navigate.productSellersPage(book.slug)}
                image={<BookImage src={book.fullImage || book.smallImage} width={206} height={330} alt={book.title} />}
                author={book.authors[0]?.fullName || ''}
                chipLabel={
                  cheapestListing
                    ? t(
                        { id: 'price' },
                        {
                          price: cheapestListing && Number(cheapestListing)
                        }
                      )
                    : t({ id: 'outOfStock' })
                }
                chipDisabled={!hasOffer}
              />
            )
          })}
        </Carousel>
      )}
    </Box>
  )
}
