'use client'

import React from 'react'
import { SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'

import { useMediaQuery } from '../../styles/useMediaQuery'
import { useTheme } from '../../styles/useTheme'

import { Box } from '../Box'
import Chevron from '../../icons/Chevron'

import { StyledSwiper, NavButton } from './styles'

interface CarouselProps {
  children: React.ReactNode
  slidesPerView?: 'auto' | number
  navigation?: boolean
  pagination?: boolean
  spaceBetween?: number
  slidesPerGroup?: number
  leftNavigation?: () => void
  rightNavigation?: () => void
}

const Carousel = ({
  children,
  slidesPerView = 'auto',
  navigation = false,
  pagination = false,
  spaceBetween,
  slidesPerGroup = 6,
  leftNavigation = () => null,
  rightNavigation = () => null
}: CarouselProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const navigationPrevRef = React.useRef<HTMLButtonElement>(null)
  const navigationNextRef = React.useRef<HTMLButtonElement>(null)

  const [swiper, setSwiper] = React.useState<any>()

  React.useEffect(() => {
    if (swiper) {
      if (navigation) {
        swiper.params.navigation.prevEl = navigationPrevRef.current
        swiper.params.navigation.nextEl = navigationNextRef.current
        swiper.params.navigation.disabledClass = 'carousel-hidden-nav-button'
      }
      swiper.navigation.init()
      swiper.navigation.update()
    }
  }, [navigation, swiper])

  const onSlideChange = (s: any) => {
    if (isMobile) {
      if (s.touches.diff < 0) {
        rightNavigation()
      } else {
        leftNavigation()
      }
    }
  }

  return (
    <Box position="relative">
      <StyledSwiper
        paginationEnabled={pagination}
        modules={[Pagination, Navigation]}
        freeMode={true}
        slidesPerGroup={slidesPerGroup}
        spaceBetween={(spaceBetween ?? isMobile) ? 8 : 24}
        slidesPerView={slidesPerView}
        lazyPreloadPrevNext={slidesPerGroup}
        onSlideChange={onSlideChange}
        pagination={
          pagination
            ? {
                dynamicBullets: true
              }
            : false
        }
        onInit={(swiperObject: any) => {
          if (isLarge) {
            // eslint-disable-next-line no-param-reassign
            swiperObject.allowTouchMove = false
            swiperObject.update()
          }
        }}
        onSwiper={setSwiper}
      >
        {React.Children.map(children, (child) => (
          <SwiperSlide style={{ maxWidth: slidesPerView === 'auto' ? 'min-content' : 'auto' }}>
            <Box mb={pagination ? '40px' : 0}>{React.cloneElement(child as React.ReactElement)}</Box>
          </SwiperSlide>
        ))}
      </StyledSwiper>
      {navigation && (
        <>
          <NavButton
            className="carousel-nav-button"
            ref={navigationPrevRef}
            aria-label="carousel-navigation-back"
            direction="left"
            variant="icon"
            sx={{ bgcolor: '#fff' }}
            onClick={leftNavigation}
          >
            <Chevron width={20} fill="#000" style={{ rotate: '90deg' }} />
          </NavButton>
          <NavButton
            className="carousel-nav-button"
            ref={navigationNextRef}
            aria-label="carousel-navigation-next"
            direction="right"
            variant="icon"
            sx={{ bgcolor: '#fff' }}
            onClick={rightNavigation}
          >
            <Chevron width={20} fill="#000" style={{ rotate: '-90deg' }} />
          </NavButton>
        </>
      )}
    </Box>
  )
}

export { Carousel, type CarouselProps }
