import { Swiper } from 'swiper/react'
import { styledComponent, styled } from '../../styles/styled'
import { Button } from '../Button'

export const NavButton = styledComponent(Button, {
  shouldForwardProp: (prop) => prop !== 'direction'
})<{ direction: 'left' | 'right' }>(({ theme, direction }) => ({
  position: 'absolute',
  top: 'calc(40% - 20px)',
  zIndex: 2,
  backgroundColor: theme.palette.neutral['000'],
  boxShadow: '0 4px 12px rgb(0 0 0 / 8%)',
  [direction]: '-16px',
  [theme.breakpoints.down('md')]: {
    [direction]: '0',
    top: 'calc(33% - 20px)'
  },
  '&.carousel-hidden-nav-button': {
    display: 'none'
  }
}))

export const StyledSwiper = styled(Swiper, {
  shouldForwardProp: (prop) => prop !== 'paginationEnabled'
})<{ paginationEnabled: boolean }>(({ theme }) => ({
  '.swiper-pagination': {
    height: '8px',
    zIndex: 999,
    '.swiper-pagination-bullet-active ': {
      backgroundColor: theme.palette.primary.main
    },
    '.swiper-pagination-bullet:not(.swiper-pagination-bullet-active)': {
      backgroundColor: theme.palette.neutral[400]
    }
  }
}))
